import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { Row, Column } from '../Grid';
import Image from '../Image';
import Icon from '../Icon';
import './styles.scss';

const CourseUnitCard = ({
  unitIdentifier,
  header,
  subheader,
  description,
  img,
  to,
}) => {
  const handleClick = (e) => {
    navigate(to);
  };

  return (
    <Row onClick={(e) => handleClick(e)} className="course-unit-card">
      <Column size={3} className="pl-0">
        <Image filename={img} />
      </Column>
      <Column size={8}>
        <h5 className="course-unit-card__unit-identifier mt-1 mb-0">
          {unitIdentifier}
        </h5>
        <h3 className="course-unit-card__header mt-0 mb-0">{header}</h3>
        <h5 className="course-unit-card__subheader mb-1">{subheader}</h5>
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="course-unit-card__description"
        />
      </Column>
      <Column size={1}>
        <Link to={to}>
          <div className="course-unit-card__icon-container">
            <Icon name="caratright" className="course-unit-card__icon" />
          </div>
        </Link>
      </Column>
    </Row>
  );
};

CourseUnitCard.propTypes = {
  /** Specifies which unit this card corresponds to. e.g. UNIT 1 */
  unitIdentifier: PropTypes.string.isRequired,
  /** Header for card. Also the name of the course. */
  header: PropTypes.string.isRequired,
  /** Subheader for card. Shows duration and curriculum overview. */
  subheader: PropTypes.string.isRequired,
  /** Body of card. Takes in a string or HTML. */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Filepath to the image. */
  img: PropTypes.string.isRequired,
  /** URL to course */
  to: PropTypes.string.isRequired,
};

export default CourseUnitCard;
