import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Image from '../../../components/Image';
import Subfooter from '../../../components/Subfooter';
import Hero from '../../../components/Hero';
import CourseUnitCard from '../../../components/CourseUnitCard';

const heroMultiImages = [
  {
    image: '/img-lead-living-earth.jpg',
    title: 'Living Earth',
    subtext: 'NGSS Curriculum | 6 Week Units',
    text:
      'Project-based units focused on engaging experiences for students to dive deeply into practical applications of biotech skills and concepts. ',
  },
  {
    image: '/img-about-our-mission.jpg',
  },
  {
    image: '/img-home-classroom-resources.jpg',
  },
];

const IndexPage = () => (
  <Layout title="Living Earth">
    <Hero className="pb-0" mediaSource={heroMultiImages} color={'purple'} />
    <Section className="living-earth__at-a-glance-section pt-0">
      <Container fullWidth className="living-earth__at-a-glance-container">
        <Row>
          <Column size={12}>
            <h5 className="mb-0">LIVING EARTH COURSE</h5>
            <h3 className="living-earth__at-a-glance-header">At a Glance</h3>
          </Column>
        </Row>
        <Row>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-instructional-support.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              INSTRUCTIONAL SUPPORT
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>Pacing guide</li>
              <li>Standards alignment</li>
              <li>Instructional Framing</li>
              <li>Integrated labs</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-course-outline.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              COURSE OUTLINE
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>2 NGSS Units</li>
              <li>6 week units</li>
              <li className="mr-3">
                Lesson Plans, Classroom Presentations and more
              </li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-topics-covered.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              TOPICS COVERED
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>Genetic variation</li>
              <li>Natural selection</li>
              <li>Genes, proteins and tissues</li>
              <li>Cellular division … and more</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-student-outcomes.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              STUDENT OUTCOMES
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>NGSS aligned inquiry</li>
              <li>Problem-based learning</li>
              <li>Increased awareness of biotechnology careers</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Container fullWidth>
        <Row className="pb-1">
          <Column size={8}>
            <h3 className="living-earth__getting-started-header mb-0.5">
              Getting Started
            </h3>
            <p className="living-earth__getting-started-desc">
              The units are designed to be used in the California Science
              Standards Three Course Model. Resources are grounded in compelling
              biotechnology storylines to maximize student exposure to biotech
              industry trends, practical applications of biotech skills and
              concepts, career paths, and industry professionals.
            </p>
          </Column>
        </Row>
        <CourseUnitCard
          unitIdentifier="Unit 1"
          header="Genetic Detectives: Investigating Inherited Diseases"
          subheader="6 weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>Inheritance of Traits:</strong> How do you counsel a
                patient on their risk of genetic disease while considering their
                health literacy? Examine risk, diagnosis and treatment of
                genetic diseases."
          img="thmb-living-earth-unit1@2x.jpg"
          to="/classroom-instruction/living-earth/unit-1"
        />
        <CourseUnitCard
          unitIdentifier="Unit 2"
          header="Community Empowerment: Eradicating Disease"
          subheader="6 weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>Structure, Function and Growth:</strong> How can we
              effectively use a community outreach campaign to educate and
              empower a community to help eradicate an infectious disease?
              Examine infectious diseases and the impact on our bodies and
              global communities."
          img="thmb-living-earth-unit2.jpg"
          to="/classroom-instruction/living-earth/unit-2"
        />
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
    </Section>
  </Layout>
);

export default IndexPage;
